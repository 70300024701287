<template>
	<div>
		<div class="text-center mb-10">
			<h1 class="headline mb-2">
				{{ $vuetify.lang.t('$vuetify.rejected.token.title') }}
			</h1>
		</div>

		<div class="text-body-2 text--secondary">
			{{ $vuetify.lang.t('$vuetify.rejected.token.subtitle') }}
		</div>
		<div class="d-flex justify-space-between mt-10">
			<v-btn
			  class="text-none ml-auto"
			  style="min-width: 88px;"
			  color="primary"
			  depressed
			  @click="next"
			>
				{{ $vuetify.lang.t('$vuetify.rejected.token.action.next') }}
			</v-btn>
		</div>
	</div>
</template>

<script>
export default {
	name: 'rejected-token',
	props: {
		query: { type: Object, default() { return {} } },
	},
	metaInfo() {
      	return {
	      	title: this.$vuetify.lang.t('$vuetify.rejected.token.title'),
	      	titleTemplate: '%s | ' + this.$vuetify.lang.t('$vuetify.header.title', { brand: this.$whitelabel.getClient().name }),
	      	htmlAttrs: { lang: this.$i18n.locale }
      	}
    },
	mounted() {
		this.$emit('progress', false);
	},
	methods: {
		next() {
			this.$emit('progress', true);
			this.$router.push({ name: 'signin-identifier', query: this.query }).catch(error => {
				this.$emit('progress', false);
			});
		}
	}
}
</script>